<template>
	<div class="content">
		<!-- Main Information -->
		<div class="lineform">
			<span class="title">Main Information</span>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col left">Vessel</div>
					<div class="tableHeader content-col must">Name</div>
				</el-col>
				<el-col :span="5">
					<el-input
						v-model="form.vesselName"
						:placeholder="form.vesselName?.toString() !== '' ? '' : errorInputTip"
						:class="form.vesselName?.toString() !== '' ? '' : 'errorInput'"
					></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">IMO Num</div>
				</el-col>
				<el-col :span="5">
					<el-input
						v-model="form.imoNum"
						:placeholder="form.imoNum?.toString() !== '' ? '' : errorInputTip"
						:class="form.imoNum?.toString() !== '' ? '' : 'errorInput'"
					></el-input>
				</el-col>
				<el-col :span="3"><div class="tableHeader content-col must">Call Sign</div></el-col>
				<el-col :span="5">
					<el-input
						v-model="form.callSign"
						:placeholder="form.callSign?.toString() !== '' ? '' : errorInputTip"
						:class="form.callSign?.toString() !== '' ? '' : 'errorInput'"
					></el-input>
				</el-col>
			</el-row>
		</div>

		<!-- Position and Time Information -->
		<div class="lineform">
			<span class="title">Position and Time Information</span>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col must">Departure Port</div>
				</el-col>
				<el-col :span="5">
					<el-input
						v-model="form.departurePort"
						:placeholder="form.departurePort?.toString() !== '' ? '' : errorInputTip"
						:class="form.departurePort?.toString() !== '' ? '' : 'errorInput'"
					></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">UNLOCODE</div>
				</el-col>
				<el-col :span="5">
					<el-input
						v-model="form.unlocode"
						:placeholder="form.unlocode?.toString() !== '' ? '' : errorInputTip"
						:class="form.unlocode?.toString() !== '' ? '' : 'errorInput'"
					></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">COSP Date</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<mydatePicker
							type="datetime"
							format="yyyy-MM-dd HH:mm"
							value-format="yyyy-MM-dd HH:mm"
							v-model="form.cospDate"
							:placeholder="form.cospDate?.toString() !== '' ? '' : errorInputTip"
							:class="form.cospDate?.toString() !== '' ? '' : 'errorInput'"
						></mydatePicker>
						<div class="unit">UTC</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3"><div class="tableHeader content-col must">COSP Position</div> </el-col>
				<el-col :span="8">
					<div class="formContent">
						<div class="tableHeader content-col left must">Latitude</div>
						<el-input
							class="lonLat"
							v-model="form.cospPositionLatitudeLeft"
							placeholder=""
							:class="form.cospPositionLatitudeLeft?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>°
						<el-input
							class="lonLat"
							v-model="form.cospPositionLatitudeCenter"
							placeholder=""
							:class="form.cospPositionLatitudeCenter?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>′
						<el-select
							class="lonLat"
							v-model="form.cospPositionLatitudeRight"
							placeholder=""
							:class="form.cospPositionLatitudeRight?.toString() !== '' ? '' : 'errorInput'"
						>
							<el-option v-for="data in NS" :label="data" :value="data" :key="data" />
						</el-select>
					</div>
				</el-col>
				<el-col :span="3"><div class="tableHeader content-col must">Longitude</div> </el-col>
				<el-col :span="8">
					<div class="formContent">
						<el-input
							class="lonLat"
							v-model="form.cospPositionLongitudeLeft"
							placeholder=""
							:class="form.cospPositionLongitudeLeft?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>°
						<el-input
							class="lonLat"
							v-model="form.cospPositionLongitudeCenter"
							:class="form.cospPositionLongitudeCenter?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>′
						<el-select
							class="lonLat"
							v-model="form.cospPositionLongitudeRight"
							placeholder=""
							:class="form.cospPositionLongitudeRight?.toString() !== '' ? '' : 'errorInput'"
						>
							<el-option v-for="data in EW" :label="data" :value="data" :key="data" />
						</el-select>
					</div>
				</el-col>
			</el-row>
		</div>

		<!-- Voyage Information -->
		<div class="lineform">
			<span class="title">Voyage Information</span>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Fore Draft</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.foreDraft" placeholder=""></el-input>
						<div class="unit">M</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Aft Draft</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.aftDraft" placeholder=""></el-input>
						<div class="unit">M</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Mid Draft</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.midDraft" placeholder=""></el-input>
						<div class="unit">M</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Draft Type</div>
				</el-col>
				<el-col :span="5">
					<el-select class="lonLat" v-model="form.draftType" placeholder="">
						<el-option label="SUMMER" value="SUMMER"></el-option>
						<el-option label="TROPICAL" value="TROPICAL"></el-option>
						<el-option label="WINTER" value="WINTER"></el-option>
					</el-select>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Load</div>
				</el-col>
				<el-col :span="5">
					<el-select class="lonLat" v-model="form.load" placeholder="">
						<el-option label="BALLAST" value="BALLAST"></el-option>
						<el-option label="LADEN" value="LADEN"></el-option>
					</el-select>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Roll Period</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.rollPeriod" placeholder=""></el-input>
						<div class="unit">S</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">GM</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.gm" placeholder=""></el-input>
						<div class="unit">M</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Cargo</div>
				</el-col>
				<el-col :span="5">
					<el-input v-model="form.cargo" placeholder=""></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Cargo Weight</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.cargoWeight" placeholder=""></el-input>
						<div class="unit">MT</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Displacement</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.displancement" placeholder=""></el-input>
						<div class="unit">MT</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">Distance To Go</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input
							v-model="form.distanceToGo"
							:placeholder="form.distanceToGo?.toString() !== '' ? '' : errorInputTip"
							:class="form.distanceToGo?.toString() !== '' ? '' : 'errorInput'"
						></el-input>
						<div class="unit">NM</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Ordered Speed</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.orderedSpeed" placeholder=""></el-input>
						<div class="unit">KTS</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Course</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.course" placeholder=""></el-input>
						<div class="unit">DEG</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Set RPM</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.setRpm" placeholder=""></el-input>
						<div class="unit">RPM</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- Fuel -->
		<div class="lineform">
			<span class="title">Position and Time Information</span>
			<el-row class="line-row">
				<el-col :span="4"><div class="tableHeader content-col">(MT)</div></el-col>
				<el-col :span="5"><div class="tableHeader content-col">HFO</div></el-col>
				<el-col :span="5"><div class="tableHeader content-col">LSFO</div></el-col>
				<el-col :span="5"><div class="tableHeader content-col">MDO</div></el-col>
				<el-col :span="5"><div class="tableHeader content-col">MGO</div></el-col>
			</el-row>
			<el-row v-for="(data, index) in fuelData" :key="index" class="line-row">
				<el-col :span="4"
					><div class="content-col" :class="data.must ? 'must' : ''">{{ data.MT }}</div></el-col
				>
				<el-col :span="5" v-for="(value, key) in data" :key="key" v-show="!['MT', 'type', 'must'].includes(key)">
					<el-input
						v-model="form[data[key]]"
						v-if="data['type'] === 'input'"
						@change="setData()"
						:placeholder="!form[data[key]] && data.must && form[data[key]] !== 0 ? errorInputTip : ''"
						:class="!form[data[key]] && data.must && form[data[key]] !== 0 ? 'errorInput' : ''"
					></el-input>
					<el-select v-model="form[data[key]]" v-else placeholder="">
						<el-option v-for="data in fuelList" :label="data.label" :value="data.value" :key="data.value" />
					</el-select>
				</el-col>
			</el-row>
		</div>
		<!-- Main Information -->
		<div class="lineform">
			<span class="title">Next Port</span>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Next Port</div>
				</el-col>
				<el-col :span="5">
					<el-input v-model="form.nextPortNextPort" placeholder=""></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">UNLOCODE</div>
				</el-col>
				<el-col :span="5">
					<el-input v-model="form.nextPortUnlocode" placeholder=""></el-input>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">ETA</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<mydatePicker
							type="datetime"
							format="yyyy-MM-dd HH:mm"
							value-format="yyyy-MM-dd HH:mm"
							v-model="form.nextPortEta"
						></mydatePicker>
						<div class="unit">UTC</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		textForm: {
			type: Object,
			default: () => {},
		},
	},
	watch: {
		textForm: {
			handler(val) {
				this.form = {
					...this.form,
					...this.textForm,
				};
				this.setData();
				console.log('🚀 ~ handler ~ this.form:', this.form);
			},
		},
	},
	data() {
		return {
			errorInputTip: 'Field is empty!',
			form: {
				vesselName: '',
				imoNum: '',
				callSign: '',
				departurePort: '',
				unlocode: '',
				cospDate: '',
				cospPositionLatitudeLeft: '',
				cospPositionLatitudeCenter: '',
				cospPositionLatitudeRight: '',
				cospPositionLongitudeLeft: '',
				cospPositionLongitudeCenter: '',
				cospPositionLongitudeRight: '',
				foreDraft: '',
				aftDraft: '',
				midDraft: '',
				draftType: '',
				load: '',
				rollPeriod: '',
				gm: '',
				cargo: '',
				cargoWeight: '',
				displancement: '',
				distanceToGo: '',
				orderedSpeed: '',
				course: '',
				setRpm: '',
				brobHfo: 0,
				brobLsfo: 0,
				brobMdo: 0,
				brobMgo: 0,
				isoClassHfo: '',
				isoClassLsfo: '',
				isoClassMdo: '',
				isoClassMgo: '',
				nextPortNextPort: '',
				nextPortUnlocode: '',
				nextPortEta: '',
			},
			fuelList: [
				{
					label: 'other',
					value: 'other',
				},
				{
					label: 'DMX',
					value: 'DMX',
				},
				{
					label: 'DMA',
					value: 'DMA',
				},
				{
					label: 'DMZ',
					value: 'DMZ',
				},
				{
					label: 'DMB',
					value: 'DMB',
				},
				{
					label: 'DMC',
					value: 'DMC',
				},
				{
					label: 'RMA10',
					value: 'RMA10',
				},
				{
					label: 'RMB30',
					value: 'RMB30',
				},
				{
					label: 'RMD50',
					value: 'RMD50',
				},
				{
					label: 'RME180',
					value: 'RME180',
				},
				{
					label: 'RMG180',
					value: 'RMG180',
				},
				{
					label: 'RMG380',
					value: 'RMG380',
				},
				{
					label: 'RMG500',
					value: 'RMG500',
				},
				{
					label: 'RMG700',
					value: 'RMG700',
				},
			],
			NS: ['N', 'S'],
			EW: ['E', 'W'],
			EWNS: ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'],
			fuelData: [
				{
					MT: 'BROB',
					HFO: 'brobHfo',
					LSFO: 'brobLsfo',
					MDO: 'brobMdo',
					MGO: 'brobMgo',
					type: 'input',
					must: true,
				},
				{
					MT: 'ISO CLASS',
					HFO: 'isoClassHfo',
					LSFO: 'isoClassLsfo',
					MDO: 'isoClassMdo',
					MGO: 'isoClassMgo',
					type: 'select',
				},
			],
		};
	},
	mounted() {
	},
	methods: {
		clear() {
			for (let key in this.form) {
				this.form[key] = '';
			}
		},
		setData() {
			let filedList = this.fuelData[0];
			for (let key in filedList) {
				if (!['MT', 'type', 'must'].includes(key)) {
					if (!this.form[filedList[key]]) {
						console.log('🚀 ~ setData ~ this.form[key]:', this.form[key]);
						this.form[filedList[key]] = 0;
					}
					if (typeof this.form[filedList[key]] !== 'number') {
						const matches = this.form[filedList[key]].match(/\d+(\.\d+)?/);
						this.form[filedList[key]] = matches ? parseFloat(matches[0]) : 0;
					}
				}
			}
		},
	},
};
</script>

<style lang="less" scoped>
.content {
	width: 100%;
	height: 100%;
	.lineform {
		width: 100%;
		margin-bottom: 15px;
		.title {
			font-size: 12px;
		}
		.grey {
			background: rgb(245, 245, 245);
			font-size: 0.875rem;
			color: #606266;
		}
		// .grey::before{
		// 	content: '*';
		// 	color: #F56C6C;
		// 	margin-right: 0.25rem;
		// }

		::v-deep .errorInput {
			border-color: #f56c6c !important;
			.el-input__inner {
				border-color: #f56c6c !important;
			}
		}
		::v-deep .form {
			margin-top: 5px;
			border: 1px solid rgba(210, 218, 228, 1);
			display: flex;
			.line {
				width: 1px;
				height: 100%;
				border: 1px solid rgba(210, 218, 228, 1);
			}
			.only {
				.el-form-item__label {
					width: 140px;
				}
			}
			.el-form-item {
				margin: 0;
				display: flex;
				.el-form-item__label {
					display: block;
					background: rgb(245, 245, 245);
					padding-left: 5px;
				}
				.el-form-item__content {
					height: 40px;
				}
			}
		}
		.line-row {
			border: 1px solid rgba(210, 218, 228, 1);
		}
		.content-col {
			height: 40px;
			line-height: 40px;
			border: 1px solid rgba(210, 218, 228, 1);
		}
		.must::after {
			content: '*';
			color: #f56c6c;
			margin-right: 0.25rem;
		}
		.left {
			float: left;
			padding: 0 5px;
		}
		.header {
			background: rgb(245, 245, 245);
			text-align: left;
			padding-left: 5px;
		}
		.tableHeader {
			background: rgb(245, 245, 245);
			text-align: center;
		}

		.formContent {
			display: flex;
			align-items: center;
			.lonLat {
				width: 100px;
			}
			.unit {
				margin: 0 5px;
				width: 30px;
				text-align: center;
			}
		}
		.grey-line {
			border: 1px solid rgba(210, 218, 228, 1);
			background: rgb(245, 245, 245);
			height: 22px;
			width: 100%;
		}
	}
}
</style>
